.error {
  width: fit-content;
  margin: 0 auto;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}

.success {
  width: fit-content;
  margin: 0 auto;
  border-bottom: 1px solid green;
  padding-bottom: 10px;
}
