.hero {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9542191876750701) 97%
    ),
    url(../../assets/images/hero-backdrop.jpg);
  height: 87.1vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: $down-boxShadow;
  display: flex;
  padding: 90px 0px;
  &__div {
    display: flex;
    max-width: 450px !important;
    flex-direction: column;
    justify-content: space-between;
  }
  &__heading {
    text-align: center;
    font-size: 26px;
    color: $background-color;
    font-family: $heading;
  }
}
.form-control {
  padding-left: 45px !important;
  border-radius: 10px !important;
  padding-top: 28px !important;
  padding-bottom: 28px !important;
  border: none !important;
  border: 1px solid $border-color !important;
  width: 100%;
}
.dropdown-item:active {
  background-color: darkgrey !important;
}
.form-control:focus {
  outline: none !important;
  border: 1px solid $main-color !important;
  box-shadow: none !important;
}
.active {
  background-color: $main-color !important;
}
.searchIcon {
  margin-left: 6px;
  position: absolute;
  left: 0;
}

@media only screen and (min-width: $max-width) {
  .hero {
    height: 85vh;
    padding-top: 90px;
  }
  .hero__div {
    width: 100%;
    max-width: 600px !important;
  }
  .hero__heading {
    font-size: 32px;
  }
}
