.button {
  background: $main-color;
  font-weight: $normal;
  font-size: 20px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: $global-spacing / 1.3;
  border: none;
  cursor: pointer;
  font-family: $heading;
}

.hollow__btn {
  margin: 0;
  background-color: transparent;
  color: $black-color;
  border: 2px solid $main-color;
}

.solid__btn {
  border: 2px solid transparent;
}

.login__btn {
  width: 100%;
  font-size: 20px;
  font-weight: $normal;
  margin-top: $global-spacing;
  margin-bottom: $global-spacing;
  background-color: transparent;
  border: 1px solid $secondary-color;
}

.contact__btn {
  width: 100%;
  margin-bottom: $global-spacing * 4;
}

.newPlace__btn {
  width: 100%;
}

.loginPage__btn {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: $main-color;
}
.publish__btn {
  padding: 12px 30px;
  font-size: 18px;
  margin-bottom: 0;
  width: 100%;
}

.orderBtn {
  background: $main-color;
  font-weight: $normal;
  font-size: 20px;
  width: fit-content;
  margin: 0;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 10px 25px;
  cursor: pointer;
  font-family: $heading;
}

.dashBoard__btn {
  padding: $global-spacing/3 20px;
  font-size: 18px;
}

.viewMore__btn {
  padding: $global-spacing/3 20px;
  margin: 0 auto;
  font-size: 16px;
}

.adminViewMore__btn {
  margin-bottom: $global-spacing * 3;
}
.hero__btn {
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: $black-color;
  border: 1px solid $main-color;
}
.nav-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggleBtn {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
  &--input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &--slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.toggleBtn--input:checked + .toggleBtn--slider {
  background-color: $main-color;
}

.toggleBtn--input:focus + .toggleBtn--slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggleBtn--input:checked + .toggleBtn--slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggleBtn--slider {
  border-radius: 34px;
}

.toggleBtn--slider:before {
  border-radius: 50%;
}

@media only screen and (min-width: $max-width) {
  .changeLanguage__btn {
    cursor: pointer;
    margin-left: 20px;
  }
  .nav-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translate(-5%, -50%);
  }
  .login__btn {
    width: 110px;
    display: inline-block;
    font-size: 16px;
    font-weight: $normal;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .solid__btn:hover {
    background-color: transparent;
    color: $black-color;
    border: 2px solid $main-color;
  }
  .hollow__btn:hover {
    filter: none;
    background-color: $main-color;
    color: $background-color !important;
  }
  .login__btn:hover {
    filter: none;
    background-color: $secondary-color;
    color: $black-color !important;
  }
  .newPlace__btn {
    max-width: 130px;
  }
}
