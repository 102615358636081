* {
  font-family: $text;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none !important;
}

a:hover {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body {
  background-color: $background-color;
  margin: 0;
  padding: 0;
  position: relative;
  padding-bottom: 108px; /* +50px for space over footer */
  min-height: 100%;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

footer {
  background-color: $secondary-color;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.custom-container {
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.disabled {
  pointer-events: none;
}

@media only screen and (min-width: $max-width) {
  .custom-container {
    max-width: 1024px;
  }
}
