.heading {
  color: $black-color;
  font-family: $heading;
  font-weight: $bold;
  text-align: center;
  margin-top: $global-spacing * 3;
  margin-bottom: $global-spacing * 3;
  font-size: 24px;
}

.subheading {
  color: $black-color;
  font-family: $heading;
  font-weight: $subheading;
  margin-top: $global-spacing * 3;
  font-size: 20px;
}

@media only screen and (min-width: $max-width) {
  .heading {
    font-size: 30px;
  }
  .subheading {
    font-size: 27px;
  }
}
