.modal-content {
  border-radius: 10px !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid $border-color !important;
  box-shadow: $input-boxShadow;
}
.MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.MuiFormControl-root {
  width: 100% !important;
}
.fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.modalForm {
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.groupForm {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.inputElem {
  width: 100%;
  padding: 14px;
  font-size: 13px;
  font-family: $text;
  border: none;
  border: 1px solid $border-color;
  box-shadow: $input-boxShadow;
  border-radius: 10px;
}

.inputElem:focus {
  outline: none !important;
  border: 1px solid $main-color;
}
.label {
  font-family: $text;
  font-size: 15px;
}

.errorLabel {
  font-size: 14px;
  color: $black-color;
  border-bottom: 1px solid $main-color;
  padding-bottom: 5px;
  width: fit-content;
  margin: 0;
  margin-top: 10px;
}
.submitBtn {
  background: darkred;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 1rem;
  border: none;
  cursor: pointer;
}
.submitBtn:hover {
  opacity: 0.8;
}

.modalBtnDiv {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.closeBtn {
  font-size: 16px;
  padding: 12px 20px;
  margin: 0;
  background: $black-color;
  margin-right: $global-spacing;
}
.sendOrderBtn {
  padding: 12px 20px;
  font-size: 16px;
  margin: 0;
}
