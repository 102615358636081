$rbt-color-primary: #f3f2f2 !default;
$rbt-color-disabled: #495057 !default;
$rbt-color-white: #fff !default;

// Hide IE's native "clear" button
.rbt .rbt-input-main::-ms-clear {
  display: none;
}

/**
 * Menu
 */
.rbt-menu {
  margin-bottom: 2px; // Spacing for dropup

  & > .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  &-pagination-option {
    text-align: center;
  }
}

/**
 * Multi-select Input
 */
$rbt-background-color-disabled: #e9ecef !default;

$rbt-border-color-focus: #80bdff !default;
$rbt-border-color-focus-invalid: #dc3545 !default;
$rbt-border-color-focus-valid: #28a745 !default;

$rbt-box-shadow-dimensions: 0 0 0 0.2rem;
$rbt-box-shadow-color: rgba(0, 123, 255, 0.25) !default;
$rbt-box-shadow-color-invalid: rgba(220, 53, 69, 0.25) !default;
$rbt-box-shadow-color-valid: rgba(40, 167, 69, 0.25) !default;

$rbt-color-focus: #495057 !default;
$rbt-placeholder-color: #6c757d !default;

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;

  // Apply Bootstrap focus styles
  &.focus {
    border-color: $rbt-border-color-focus;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color;
    color: $rbt-color-focus;
    outline: 0;
  }

  &.form-control {
    height: auto;
  }

  // BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
  &.form-control[disabled] {
    background-color: $rbt-background-color-disabled;
    opacity: 1;
  }

  &.is-invalid.focus {
    border-color: $rbt-border-color-focus-invalid;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-invalid;
  }

  &.is-valid.focus {
    border-color: $rbt-border-color-focus-valid;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-valid;
  }

  // Apply Bootstrap placeholder styles
  input {
    // Firefox
    &::-moz-placeholder {
      color: $rbt-placeholder-color;
      opacity: 1;
    }

    // Internet Explorer 10+
    &:-ms-input-placeholder {
      color: $rbt-placeholder-color;
    }

    // Safari and Chrome
    &::-webkit-input-placeholder {
      color: $rbt-placeholder-color;
    }
  }

  .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
  }

  .rbt-input-main {
    margin: 1px 0 4px;
  }
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;

  &-lg {
    font-size: 24px;
  }
}

/**
 * Token
 */
$rbt-token-background-color: #e7f4ff !default;
$rbt-token-color: $rbt-color-primary !default;

$rbt-token-disabled-background-color: rgba(0, 0, 0, 0.1) !default;
$rbt-token-disabled-color: $rbt-color-disabled !default;

$rbt-token-active-background-color: $rbt-color-primary !default;
$rbt-token-active-color: $rbt-color-white !default;

.rbt-token {
  background-color: $rbt-token-background-color;
  border: 0;
  border-radius: 0.25rem;
  color: $rbt-token-color;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;

  &-disabled {
    background-color: $rbt-token-disabled-background-color;
    color: $rbt-token-disabled-color;
    pointer-events: none;
  }

  &-removeable {
    cursor: pointer;
    padding-right: 21px;
  }

  &-active {
    background-color: $rbt-token-active-background-color;
    color: $rbt-token-active-color;
    outline: none;
    text-decoration: none;
  }

  & &-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
  }
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none; /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;

  &-lg {
    width: 46px;
  }

  & .rbt-close {
    margin-top: -4px;
    pointer-events: auto; /* Override pointer-events: none; above */
  }
}

.has-aux .rbt-input {
  padding-right: 34px;
}

// Default highlight style
.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
  flex: 1;

  // Form-controls within input-groups have a higher z-index.
  & .rbt-input-hint,
  & .rbt-aux {
    z-index: 5;
  }

  &:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
