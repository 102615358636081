/* Colors */
$main-color: #ff4f4f;
$secondary-color: #f3f2f2;
$background-color: #ffffff;
$black-color: #050505;
$border-color: #e5e5e5;

/* Box Shadow */
$down-boxShadow: 0px 0px 17px 4px rgba(0, 0, 0, 0.5);
$cards-boxShadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
$input-boxShadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);

/* Fonts */
$heading: 'Inter', sans-serif;
$text: 'Roboto', sans-serif;

$light: 300;
$normal: 400;
$semibold: 500;
$subheading: 600;
$bold: 700;

/* spacing */
$global-spacing: 1rem;

/* Max Width on desktop */
$max-width: 768px;
