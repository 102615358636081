.inbox-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: $cards-boxShadow;
  border-radius: 10px;
  margin: $global-spacing * 2 0px;
  align-items: center;
  max-width: 500px;
  height: 300px;
  padding: 20px;
  width: 100%;
  position: relative;
}

.inbox-div {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.inbox-info {
  display: flex;
  width: 100%;
  // width: 150%;
  flex-wrap: nowrap;
  flex-direction: column;
  align-self: flex-start;
}

.inbox-name {
  font-size: 16px;
  display: block;
  font-weight: $subheading;
  font-family: $heading;
  margin-bottom: 12px;
}
.inbox-messageDiv {
  width: 100%;
  max-height: 145px;
  overflow: scroll;
}
.inbox-message {
  margin: 0;
}
.inbox-created {
  margin-bottom: 15px;
}
.inbox-dateDiv {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $max-width) {
  .inbox-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $global-spacing * 2;
    margin-bottom: $global-spacing * 2;
    width: 100%;
  }

  .inbox-cards {
    transition: 0.4s;
    margin: 20px 0px 20px 20px;

    width: 100%;
    max-width: 450px;
  }
}
